import {
  BonusDispatcher,
  BonusType,
  Currency,
  Gateway,
  TransactionStates,
  TransactionTypes,
} from '@/enums/core';

import { exception, startAndEndDay } from '@/utils/core';

import { prisma } from '@/utils/prisma';

import Referral from '@/utils/referrals';

import { ITransaction } from '@/interfaces/core';

interface QueryResponse {
  status: boolean;
  data: any;
}

export const findWallet = async (user_id: number, currency: string) => {
  try {
    const wallet = await prisma.wallets.findFirst({
      where: {
        user_id,
        currency,
        status: true,
      },
      select: {
        currency: true,
        status: true,
        address: true,
        id: true,
        network: true,
      },
    });

    return wallet;
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findWallet',
      method: 'GET',
      req: { user_id, currency },
    });
    return null;
  }
};

export const findWalletByAddress = async (address: string) => {
  const wallet = await prisma.wallets.findFirst({
    where: {
      address,
    },
  });
  return wallet;
};

export const createWallet = async (
  user_id: number,
  currency: string,
  address: string,
  method: number,
  type?: number,
  network?: number,
  bank?: string,
  status = true,
  // eslint-disable-next-line max-params
) => {
  const newWallet = await prisma.wallets.create({
    data: {
      wallet_id: 0,
      user_id,
      currency,
      address,
      method,
      type: type || 1,
      status,
      network: network || 0,
      bank: bank || '',
    },
  });

  return newWallet;
};

export const BonusDeposit = async (amountBonus: number, user_id: number) => {
  const date = new Date();
  date.setHours(date.getHours() - 6);
  const today = date.toISOString().slice(0, 10);
  /* eslint-disable no-console */
  console.log('today => ', today);
  const bonus = await prisma.bonuses.findFirst({
    where: {
      dispatcher: BonusDispatcher.NEXT_DEPOSIT,
      type: BonusType.PERCENTAGE,
      active: true,
      days: { array_contains: today },
    },
    select: {
      amount: true,
      rules: true,
    },
  });

  if (bonus && bonus.amount && bonus.amount > 0 && (bonus.rules && amountBonus >= (bonus.rules as any)?.min_deposit && amountBonus <= (bonus.rules as any)?.max_deposit)) {
    const { start, end } = startAndEndDay();
    const transaction = await prisma.$queryRaw<{ id: number }[]>`
      SELECT id FROM transactions 
      WHERE user_id = ${user_id} 
      AND bonus_percentage != 0 
      AND created_at >= CAST(${start} AS TIMESTAMP) 
      AND created_at <= CAST(${end} AS TIMESTAMP)
    `;

    if (transaction.length === 0) {
      const bonusAmount = ((bonus.amount ?? 0) * amountBonus) / 100;
      return { amountB: bonusAmount > 0 ? bonusAmount + amountBonus : amountBonus, percentage: bonus.amount ? bonus.amount / 100 : 0 };
    }

    return { amountB: amountBonus, percentage: 0 };
  }
  return { amountB: amountBonus, percentage: 0 };
};

export const updateBalance = async (
  user_id: number,
  status: number,
  type: number,
  amount: number,
  currency: number,
  gateway: number,
  id_txn: string,
  deposit_id: string,
  description: string,
  bonusPercentage?: number, // TODO: change all data to an object
  // eslint-disable-next-line max-params
) => {
  try {
    let amountBonus = amount;
    let bonusPercent = bonusPercentage ?? 0;
    if (status === TransactionStates.COMPLETE) {
      const { amountB, percentage } = await BonusDeposit(amountBonus, user_id);
      amountBonus = amountB;
      bonusPercent = percentage;
    }

    // find if the transaction already exists if so then update the status
    const existingTransaction = await prisma.transactions.findFirst({
      where: {
        OR: [{ deposit_id }, { id_txn }],
      },
    });

    // If the transaction exists, update it
    if (existingTransaction) {
      if (existingTransaction?.gateway === Gateway.TIGO) {
        return {
          transaction: existingTransaction,
          processed: true,
        };
      }
      const updatedTransaction = await prisma.transactions.update({
        where: {
          id: existingTransaction.id,
        },
        data: {
          status,
          amount: amountBonus,
          description,
          bonus_percentage: bonusPercent,
          deposit_id,
        },
      });

      if (type === TransactionTypes.DEPOSIT && status === TransactionStates.COMPLETE) {
        const referral = new Referral(user_id);
        await referral.commissionByApi(amount);
      }

      return {
        transaction: updatedTransaction,
        processed: true,
      };
    }

    const newTransaction = await prisma.transactions.create({
      data: {
        user_id,
        status,
        type,
        amount: amountBonus,
        currency,
        gateway,
        id_txn,
        deposit_id,
        description,
        bonus_percentage: bonusPercent,
        created_at: new Date(),
      },
    });

    return {
      transaction: newTransaction,
      processed: false,
    };
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: updateBalance',
      method: 'POST',
      req: {
        user_id,
        status,
        type,
        amount,
        currency,
        gateway,
        id_txn,
        deposit_id,
        description,
      },
    });
    return null;
  }
};

export async function findDepositWallet(
  address: string,
): Promise<QueryResponse> {
  try {
    const wallet = await prisma.wallets.findFirst({
      where: {
        address,
      },
      select: {
        currency: true,
        status: true,
        address: true,
        user_id: true,
        id: true,
        network: true,
      },
    });

    return { status: true, data: wallet };
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findDepositWallet',
      method: 'GET',
      req: address,
    });
    return { status: false, data: null };
  }
}

export async function findFirstDeposit(user_id: number) {
  try {
    const user = await prisma.users.findFirst({
      where: {
        id: user_id,
      },
      select: {
        email: true,
        profiles: {
          select: {
            first_deposit: true,
          },
        },
      },
    });
    const firstDeposit = user?.profiles?.first_deposit;

    const data = {
      email: user?.email,
      first_deposit: firstDeposit ?? false,
    };
    return data;
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findFirstDeposit',
      method: 'GET',
      req: { user_id },
    });
    return {
      email: undefined,
      first_deposit: undefined,
    };
  }
}

export async function findUserInAltenar(user_id: number) {
  try {
    const bonus = await prisma.users.findFirst({
      where: {
        id: user_id,
        status: true,
        altenar: {
          not: null,
        },
      },
      select: {
        altenar: true,
      },
    });
    return !!bonus?.altenar;
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findBonusByDeposit',
      method: 'GET',
      req: { user_id },
    });
    return false;
  }
}

export async function updateBalanceByAddress({
  address,
  status,
  amount,
  idTxn,
  depositId,
}: {
  address: string;
  amount: number;
  status: number;
  idTxn: string;
  depositId: string;
}) {
  try {
    if (!address || address === '') {
      return { status: false, data: null };
    }
    const wallet = await findWalletByAddress(address);
    if (wallet) {
      await updateBalance(
        wallet.user_id,
        status,
        TransactionTypes.DEPOSIT,
        amount,
        Currency.HNL,
        Gateway.COINPAYMENTS,
        idTxn,
        depositId,
        '',
      );
      return { status: true, data: wallet };
    }
    return { status: false, data: null };
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: updateBalanceByAddress',
      method: 'POST',
      req: { address, amount },
    });
    return { status: false, data: null };
  }
}

export const arrayBankDepositForm = () => {
  return [
    {
      disabled: false,
      id: 'bank',
      label: 'Banco',
      placeholder: 'Banco',
      type: 'select',
    },
    {
      disabled: false,
      id: 'date',
      label: 'Fecha de depósito',
      placeholder: 'Fecha de depósito',
      type: 'date',
    },
    {
      disabled: false,
      id: 'voucher',
      label: 'N Referencia',
      placeholder: 'N Referencia',
      type: 'text',
    },
    {
      disabled: false,
      id: 'value',
      label: 'Valor',
      placeholder: 'Valor',
      type: 'text',
    },
    {
      disabled: false,
      id: 'observations',
      label: 'Observaciones',
      placeholder: 'Observaciones',
      type: 'text',
      fullWidth: true,
    },
    {
      disabled: false,
      id: 'file',
      label: 'Archivo',
      type: 'file',
    },
  ];
};

export const createTransaction = async (
  data: ITransaction,
) => {
  const {
    userId,
    status,
    type,
    amount,
    currency,
    gateway,
    description,
  } = data;

  const newTransaction = await prisma.transactions.create({
    data: {
      user_id: userId,
      status,
      type,
      amount,
      currency,
      gateway,
      description,
    },
  });

  return newTransaction;
};

export const cancelUnlimitPayment = async ({ id, deposit_id }: { id: number; deposit_id: string }) => {
  try {
    const transaction = await prisma.transactions.findFirst({
      where: {
        user_id: id,
        deposit_id,
        status: TransactionStates.PENDING,
      },
    });

    if (!transaction) {
      return { message: 'Transacción procesada' };
    }

    await prisma.transactions.update({
      where: {
        id: transaction.id,
      },
      data: {
        status: TransactionStates.CANCELLED,
      },
    });

    return { message: 'Transacción cancelada' };
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: cancelUnlimitPayment',
      method: 'POST',
      req: { id, deposit_id },
    });
    return { message: 'Error al cancelar la transacción' };
  }
};

export const getGateways = async (userId: number) => {
  try {
    const gateways = await prisma.profiles.findUnique({
      where: {
        user_id: userId,
      },
      select: {
        gateways: true,
      },
    });

    return gateways?.gateways;
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: getGateways',
      method: 'GET',
      req: { userId },
    });
    return null;
  }
};
