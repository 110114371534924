'use client';

import { useEffect, useState } from 'react';

import Link from 'next/link';

import { useSession } from 'next-auth/react';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';

import { useAuthStore } from '@/stores/userStore';

import { decrypt } from '@/utils/core';

import { PAYPHONE_CONVERSION_RATE } from '@/constants/core';

import { Button } from '@/components/ui/button';
import {
  Dialog, DialogContent, DialogHeader, DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';

import { Separator } from '@/components/ui/separator';

import { useToast } from '@/components/ui/use-toast';

import { getPayPhoneCallback } from '../services';

const CreditCardOption = () => {
  const [showPayPhone, setShowPayPhone] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState('');
  const [isOtherValue, setIsOtherValue] = useState(false);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [error, setError] = useState('');
  const { toast } = useToast();
  const { account } = useAuthStore();
  const { data: session } = useSession();
  const minDeposit = 25;
  const maxDeposit = 12500;
  const payPhoneToken = process.env.NEXT_PUBLIC_PAYPHONE_TOKEN;
  const payPhoneStoreId = process.env.NEXT_PUBLIC_PAYPHONE_STORE_ID;

  useEffect(() => {
    if (account.kycLevel === 1 || account.kycLevel === 2) {
      setIsVerify(true);
    } else {
      toast({
        title: 'Verificación requerida',
        description: 'Necesitas completar la verificación de identidad para depositar dinero',
        variant: 'destructive',
      });
    }
  }, [account, toast]);

  const handleAmountClick = (amount: number) => {
    setError('');
    setSelectedAmount(amount.toFixed(2)); // Ensure the value has two decimal places when selected
    setIsOtherValue(false);
  };

  const handleOtherValueClick = () => {
    setSelectedAmount('');
    setIsOtherValue(true);
  };

  const togglePromoCode = () => {
    setShowPromoInput(!showPromoInput);
  };

  useEffect(() => {
    // Check if the stylesheet is already added
    const existingLink = document.querySelector('link[href="https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.css"]');
    const link = document.createElement('link');
    if (!existingLink) {
      link.rel = 'stylesheet';
      link.href = 'https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.css';
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    const handleDepositDialog = async () => {
      try {
        if (!session || !session.user) {
          toast({
            title: 'Sesion no iniciada',
            description: 'Inicia sesion e intenta nuevamente',
            variant: 'destructive',
          });
          return;
        }
        const userId = await decrypt(session.user?.data, session.user?.tag);

        if (userId) {
          const timestamp = new Date().getTime();
          const uniqueTransactionId = `${userId}-${timestamp}`;

          const conversionRate = Number(PAYPHONE_CONVERSION_RATE); // 1 USD = 0.040 HNL
          const amountInUSD = parseFloat(selectedAmount) * conversionRate;

          const script = document.createElement('script');
          script.src = 'https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.js';
          script.type = 'module';
          document.head.appendChild(script);

          script.onload = () => {
            if ((window as any).PPaymentButtonBox) {
              new (window as any).PPaymentButtonBox({
                token: payPhoneToken,
                amount: Math.round(amountInUSD * 100),
                amountWithoutTax: Math.round(amountInUSD * 100),
                amountWithTax: 0,
                tax: 0,
                service: 0,
                tip: 0,
                reference: userId,
                clientTransactionId: uniqueTransactionId,
                storeId: payPhoneStoreId,
                email: account.email,
              }).render('pp-button');
            }
          };
          const res = await getPayPhoneCallback({
            userId,
            amount: selectedAmount,
            clientTransactionId: uniqueTransactionId,
          });

          if (!res.processed) {
            toast({
              title: 'Error',
              description: 'Error iniciando pago',
              variant: 'destructive',
            });
          }

          script.onerror = () => {
            setShowPayPhone(false);
            toast({
              title: 'Error',
              description: 'Error iniciando pago',
              variant: 'destructive',
            });
          };
        }
      } catch (Depositerror: any) {
        toast({
          title: 'Error',
          description: `Error iniciando pago: ${Depositerror.message}`,
          variant: 'destructive',
        });
      }
    };
    if (showPayPhone) {
      handleDepositDialog();
    }
  }, [showPayPhone]);

  const handleDeposit = (e: any) => {
    e.preventDefault();
    setShowPayPhone(true);
  };

  const handleAmountChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    // This regex ensures only numbers and a single dot are entered
    if (/^\d*\.?\d*$/.test(value)) {
      const numericValue = parseFloat(value);
      if (numericValue < minDeposit) {
        setError('El monto mínimo de depósito es L 25');
      } else if (numericValue > maxDeposit) {
        setError('El monto máximo de depósito es L 12.500');
      } else {
        setError('');
      }
      setSelectedAmount(value);
    }
  };

  const formatFinalAmount = () => {
    if (selectedAmount !== '') {
      setSelectedAmount(parseFloat(selectedAmount).toFixed(2));
    }
  };

  return (
    <div>
      {!showPayPhone && (
        <>
          <p className="text-xs text-sivarbet-text ">Acreditación inmediata</p>
          <Separator className="my-2 bg-sivarbet-border" />
          <h3 className="text-lg font-semibold text-center">Completa tu depósito</h3>

          <Separator className="my-2 bg-sivarbet-border" />
          <div onClick={togglePromoCode} className="cursor-pointer flex justify-between">
            <span className="promo text-sivarbet-text">Código promocional</span>
            <Link href="/" className="flex gap-1 items-center text-sivarbet-primary">
              <span>
                {showPromoInput ? <CiCircleMinus /> : <CiCirclePlus />}
              </span>
              <span>Ingresa el código</span>
            </Link>
          </div>
          {showPromoInput && (
            <div className="my-4">
              <Input
                type="text"
                value={promoCode}
                onChange={(e) => { return setPromoCode(e.target.value); }}
                placeholder="Codigo"
                className="input-form-text"
              />
            </div>
          )}
          <ul className="flex items-stretch justify-between gap-4 my-4 w-full">
            {[250, 500, 1000].map((itm) => {
              return (
                <li
                  key={itm}
                  onClick={() => { return handleAmountClick(itm); }}
                  className="cursor-pointer p-2 border-none bg-sivarbet-primary hover:bg-sivarbet-primary-hovers rounded w-full"
                >
                  <h5 className="text-center">
                    L
                    {itm}
                  </h5>
                </li>
              );
            })}
            <li onClick={handleOtherValueClick} className="cursor-pointer p-2 border-none bg-sivarbet-primary hover:bg-sivarbet-primary-hover rounded text-nowrap">
              Otro valor
            </li>
          </ul>

          <form onSubmit={handleDeposit}>
            <div className="mb-4">
              <Input
                type="number"
                id="dAmount"
                placeholder="Monto de depósito"
                value={selectedAmount}
                onChange={handleAmountChange}
                onBlur={formatFinalAmount}
                disabled={!isOtherValue}
                min="24"
                step="0.01"
                className="input-form-text"
              />
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>
            <Separator className="my-2 bg-sivarbet-border" />
            <div className="my-3">
              <div className="items-center flex justify-between">
                <span>Total</span>
                <span>
                  L
                  {' '}
                  {selectedAmount ? parseFloat(selectedAmount).toFixed(2) : '0'}
                </span>
              </div>
              {/* {selectedAmount ? (
                <div className="items-center flex justify-between text-sivarbet-secondary">
                  <IconGift width={20} height={18} className="ms-2" />
                  <span>
                    + L
                    {' '}
                    {selectedAmount ? (parseFloat(selectedAmount) * 0.05).toFixed(2) : '0'}
                  </span>
                </div>
              ) : null} */}
            </div>
            <Separator className="my-2 bg-sivarbet-border" />
            <div className="btn-area">
              <Button type="submit" onClick={handleDeposit} disabled={!isVerify || selectedAmount === '' || error !== ''} className="deposit-complete w-full p-2 bg-sivarbet-primary hover:bg-sivarbet-primary-hover text-white rounded">
                <span>Completar Depósito</span>
              </Button>
            </div>
            <p className="text-center text-sm mt-4 text-white">
              Serás redirigido al sitio de PayPhone para completar tu depósito.
            </p>
          </form>
        </>
      )}
      <Dialog open={showPayPhone} onOpenChange={setShowPayPhone}>
        <DialogHeader>
          <DialogTitle>Completa tu depósito</DialogTitle>
        </DialogHeader>
        <DialogContent className="max-md:w-full max-md:h-full">
          <div id="pp-button" />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreditCardOption;
