import { useEffect, useState } from 'react';

// import PromoSvg from '@/features/svgDesing/PromoSvg';

import { core } from '@/localization';

import { useAuthStore } from '@/stores/userStore';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import BankDeposit from './BankDeposit';
import CashDeposit from './CashDeposit';
import CreditAndDebitCard from './CreditAndDebitCard';
import CryptoQRCode from './CryptoQRCode';
import { getGateways } from '../services';

/* eslint-disable */
interface Props {
  handleCashDepositForm?: () => void;
  showBankOptions?: Boolean;
}
/* eslint-enable */
const ContentDeposit = ({ handleCashDepositForm, showBankOptions }:Props) => {
  const [showCryptoForm, setShowCryptoForm] = useState(false);
  const [showDebitCardForm, setShowDebitCardForm] = useState(false);
  const [showBankForm, setShowBankForm] = useState(showBankOptions);
  const [showCashForm, setShowCashForm] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const { setGateways } = useAuthStore();

  useEffect(() => {
    const updateGateways = async () => {
      const gateways = await getGateways();

      if (gateways) {
        setGateways(gateways);
      }
    };
    updateGateways();
  }, [showDebitCardForm, showBankForm]);

  // const handleDepositClick = async () => {
  //   setShowDebitCardForm(false);
  //   setShowBankForm(false);
  //   setShowCashForm(false);
  //   setShowCryptoForm(true);
  // };

  const handleDebit = async () => {
    setShowCryptoForm(false);
    setShowBankForm(false);
    setShowCashForm(false);
    setShowDebitCardForm(true);
  };

  const handleBankDeposit = async () => {
    setShowCryptoForm(false);
    setShowDebitCardForm(false);
    setShowCashForm(false);
    setShowBankForm(true);
  };

  const handleCashDeposit = async () => {
    setShowCryptoForm(false);
    setShowDebitCardForm(false);
    setShowBankForm(false);
    setShowCashForm(true);
  };
  return (
    <div className="space-y-4 h-full">
      <div>
        <p className="text-center mb-7 font-normal">
          {core.chooseDepositMethod}
        </p>
        <div className="grid grid-cols-2 gap-4">
          <Button
            className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
            onClick={handleBankDeposit}
          >
            <span className="text-lg icon icon-bank" />
            <span className="md:text-base">{core.bankDeposit}</span>
          </Button>
          <Button
            className="relative flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
            onClick={handleDebit}
          >
            {/* <PromoSvg
              height={30}
              width={60}
              className="
                    absolute
                    top-[-10%] left-[-4%]
                    rotate-[-45deg]
                    shadow-sm
                    sm:top-[-12%] sm:left-[-5%] sm:h-[30px] sm:w-[60px]
                    md:top-[-12%] md:left-[-6%] md:h-[40px] md:w-[80px]
                  "
            /> */}
            <span className="icon icon-credit" />
            <span className="md:text-base">{core.creditCard}</span>
          </Button>
          {/* <Button
            className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
            onClick={handleDepositClick}
          >
            <span className="text-lg icon icon-crypto" />
            <span className="md:text-base">{core.crypto}</span>
          </Button> */}

          <Button
            className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
            onClick={handleCashDeposit}
          >
            <span className="icon icon-cash" />
            <span className="md:text-base">{core.cashPayment}</span>
          </Button>
        </div>
      </div>
      {showCryptoForm && (
        <div className="flex-grow overflow-auto">
          <Separator className="bg-sivarbet-border" />
          <CryptoQRCode
            walletAddress={walletAddress}
            setWalletAddress={setWalletAddress}
          />
        </div>
      )}
      {showDebitCardForm && (
        <div className="h-full">
          {' '}
          <Separator className="bg-sivarbet-border" />
          {' '}
          <CreditAndDebitCard />
          {' '}
        </div>
      )}
      {showBankForm && (
        <div>
          <Separator className="bg-sivarbet-border" />
          <BankDeposit handleCashDeposit={handleCashDepositForm} />
        </div>
      )}
      {showCashForm && (
        <div>
          <Separator className="bg-sivarbet-border" />
          <CashDeposit />
        </div>
      )}
    </div>
  );
};

export default ContentDeposit;
