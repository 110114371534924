import { useEffect, useState } from 'react';

import Image from 'next/image';

import { useSession } from 'next-auth/react';

import VoucherInProcess from '@/features/deposit/components/VoucherInProcess';
import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import { decrypt, validateIdsForVoucherModule } from '@/utils/core';

import { Button } from '@/components/ui/button';

import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/components/ui/tabs';

import BankContent from './BankContent';

const bankDetails = {
  banpais: {
    details:
      'Opción 1:Cta de ahorro Lempiras: 215990026290',
    rows: 4,
  },
  bac: {
    details: 'Opción 1: AHORROS: 748498871',
    rows: 4,
  },
};

interface Props {
  handleCashDeposit:any
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BankDeposit = ({ handleCashDeposit }:Props) => {
  const { data: session } = useSession();
  const { setOpenDeposit } = userUIStore();
  const { vouchers, gateways } = useAuthStore();
  const [userId, setUserId] = useState<any>(null);
  const [hasVoucher, setHasVoucher] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userIdLocal = await decrypt(session?.user?.data ?? '', session?.user?.tag ?? '');
        setUserId(userIdLocal);

        const res = await fetch('/api/voucher');
        const data = await res.json();
        setHasVoucher(data.status);
      } catch (error) {
        setHasVoucher(null);
      }
    };

    fetchData();
  }, [session?.user?.data, session?.user?.tag]);

  return (
    <div className="p-3">
      <Tabs>
        <TabsList className="grid grid-cols-2 gap-3 w-full h-full bg-sivarbet-secondary-bg py-2 mt-2">
          <TabsTrigger
            value="banpais"
            className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full"
            onClick={() => { return setOpenDeposit(true); }}
          >
            <Image
              src="/img/footer/banpais.png"
              alt="Banpais"
              width={100}
              height={100}
            />
          </TabsTrigger>
          <TabsTrigger
            value="bac"
            className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full"
            onClick={() => { return setOpenDeposit(true); }}
          >
            <Image src="/img/footer/bac.png" alt="Bac" width={100} height={100} />
          </TabsTrigger>
        </TabsList>

        <TabsContent value="banpais">
          <BankContent bankDetails={bankDetails} selectedPaymentMethod="banpais" />
          {(validateIdsForVoucherModule(userId) || (gateways && gateways?.vouchers)) && (
            <div>
              {hasVoucher !== null && hasVoucher !== true ? (
                <VoucherInProcess />
              ) : (
                <Button
                  className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
                  onClick={
                    () => {
                      localStorage.setItem('defaultDepositBank', '1');
                      handleCashDeposit();
                    }
                  }
                >
                  <span className="icon icon-cash" />
                  <span className="text-lg">Enviar comprobante </span>
                </Button>
              )}
            </div>
          )}
        </TabsContent>
        <TabsContent value="bac">
          <BankContent bankDetails={bankDetails} selectedPaymentMethod="bac" />
          {(validateIdsForVoucherModule(userId) || gateways?.vouchers) && (
            <div>
              {vouchers && vouchers !== '0' ? (
                <VoucherInProcess />
              ) : (
                <Button
                  className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
                  onClick={
                    () => {
                      localStorage.setItem('defaultDepositBank', '2');
                      handleCashDeposit();
                    }
                  }
                >
                  <span className="icon icon-cash" />
                  <span className="text-lg">Enviar comprobante</span>
                </Button>
              )}
            </div>
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default BankDeposit;
